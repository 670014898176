import * as React from "react"
import Layout from "../components/layout2023"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import ProcessV2 from "../componentsv2/processv2/processv2"

const Process = () => {
  return (
    <Layout nav={"hidden"} parentClass={`newtheme2024`}>
      <Seo title="Process" />
      <ProcessV2 />
      <FooterV2 version="v2" />
    </Layout>
  )
}
export default Process
